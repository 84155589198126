import HeroSection from '../../components/sections/Hero'
import WeProvideSection from '../../components/sections/WeProvide'
import ModelsSection from '../../components/sections/Models'
import ShowcasesSection from '../../components/sections/Showcases'
import TrustedBySection from '../../components/sections/TrustedBy'
import BlogSection from '../../components/sections/Blog'
import CustomersThink from '../../components/sections/CustomersThink'
import GetInTouchSection from '../../components/sections/GetInTouch'
import ClutchAchievements from '../../components/sections/ClutchAchievements'
import SectionsContainer from '../../components/SectionsContainer'

interface HomePageProps {
  sections: any
}

const HomePage = ({ sections }: HomePageProps) => {
  const getComponent = (component: any, key: any) => {
    // eslint-disable-next-line no-underscore-dangle
    const componentName = component.__typename

    switch (componentName) {
      case 'ComponentSectionsHero': {
        return <HeroSection key={key} {...component} />
      }
      case 'ComponentSectionsWeProvide': {
        return <WeProvideSection key={key} {...component} />
      }
      case 'ComponentSectionsShowcases': {
        return <ShowcasesSection key={key} {...component} />
      }
      case 'ComponentSectionsTrustedBy': {
        return <TrustedBySection key={key} {...component} />
      }
      case 'ComponentSectionsBlog': {
        return <BlogSection key={key} {...component} />
      }
      case 'ComponentSectionsModels': {
        return <ModelsSection key={key} {...component} />
      }
      case 'ComponentSectionsCustomersThink': {
        return <CustomersThink key={key} {...component} />
      }
      case 'ComponentComponentsGetInTouch': {
        return <GetInTouchSection key={key} {...component} />
      }
      case 'ComponentSectionsClutchAchievements': {
        return <ClutchAchievements key={key} {...component} />
      }
      default: {
        return <></>
      }
    }
  }

  return (
    <SectionsContainer>
      {sections &&
        sections
          .filter(Boolean)
          .map((component: any, index: number) =>
            getComponent(component, `generator-element-${component.id}-${index}`)
          )}
    </SectionsContainer>
  )
}

export default HomePage
