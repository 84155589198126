import { GetServerSidePropsContext } from 'next'
import { findBlogs, findShowcases, getHomePage } from '../api'
import HomePage from '../containers/Home'
import getLocalesConfig from '../utils/getLocalesConfig'
import PageMetadata, { PageMetadataProps } from '../components/PageMetadata'
import getPublicationState from '../utils/getPublicationState'

interface HomePageProps {
  title: string
  description: string
  sections: any
  metadata: PageMetadataProps
}

const Home = ({ sections, metadata }: HomePageProps) => (
  <>
    <PageMetadata {...metadata} />
    <HomePage sections={sections} />
  </>
)

export const getStaticProps = async (ctx: GetServerSidePropsContext) => {
  const { locale } = await getLocalesConfig(ctx.locale)
  const publicationState = getPublicationState(ctx)
  const paginationPayload = { locale, page: 1, pageSize: 10, publicationState }
  const [content, blogs, showcases] = await Promise.all([
    getHomePage({ locale, publicationState }),
    findBlogs(paginationPayload),
    findShowcases(paginationPayload)
  ])
  const sections = content?.attributes?.sections.map((section: any) => {
    if (section?.__typename === 'ComponentSectionsBlog') {
      return {
        ...section,
        blog_articles: blogs
      }
    }
    if (section?.__typename === 'ComponentSectionsShowcases') {
      return {
        ...section,
        showcases
      }
    }
    return section
  })

  return {
    props: {
      metadata: content?.attributes?.metadata,
      sections
    },
    revalidate: 60 * 60 * 24
  }
}

export default Home
